import { api } from '@api';
import { H4, H5 } from '@pixiepkg/react';
import { loginModal } from '@shared/stores/loginModal.store';
import { sessionAtom } from '@shared/stores/session.store';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import Image from 'next/image';
import { useCallback, useEffect, useState } from 'react';
import { MemoizedSessionsModal as SessionsModal } from './components/SessionsModal';
import { MemoizedUserInfoModal as UserInfoModal } from './components/UserInfoModal';
import styles from './style/Gamification.module.scss';
import { useWindowSize } from '@shared/hooks/useWindownSize';
import ReactConfetti from 'react-confetti';
import { useTranslation } from 'next-i18next';

type Questions = {
	id: string;
	name: string;
	phone: string;
	content: string;
	createdAt: number;
	updatedAt: number;
	deletedAt: number;
};

export type QuestionsResponse = {
	questions: Questions[];
	total: number;
};

export const Gamification = ({ audit = true }) => {
	const [session] = useAtom(sessionAtom);
	const [userInfoModal, setUserInfoModal] = useState({ isOpen: false, from: 'home_page' });
	const [, setOpenLoginModal] = useAtom(loginModal);
	const [showSessionModal, setShowSessionModal] = useState(false);
	const [myQuestions, setMyQuestions] = useState<QuestionsResponse>();
	const { width } = useWindowSize();
	const [confetti, setConfetti] = useState(false);
	const { t } = useTranslation('home');

	const handleOpenLoginModal = () => {
		if (!session.isSignedIn) {
			setOpenLoginModal({ isOpen: true, from: 'home_page' });
		}
	};

	useEffect(() => {
		if (session.isSignedIn) {
			api.getOwnQuestion()
				.then((res) => {
					setMyQuestions(res.data);
				})
				.catch((error) => console.log(error));
		} else {
			setMyQuestions(undefined);
		}
	}, [session.isSignedIn]);

	const handleOpenModal = () => {
		if (session.isSignedIn && myQuestions?.total === 0) {
			setUserInfoModal({ isOpen: true, from: 'home_page' });
		}
	};

	const handleToggleSessionModal = useCallback(() => {
		if (session.isSignedIn) {
			setShowSessionModal((prev) => !prev);
		}
	}, [session.isSignedIn, showSessionModal]);

	return (
		<div className={classNames(styles.container, { [styles.audit]: audit })}>
			<div className="h-[244px] w-full rounded-3xl bg-gray-100 px-[56px] py-[32px] md:h-full sm:px-[24px] sm:py-[16px]">
				<H4 bold>{t('gamification.taskTitle')}</H4>
				<div className="mt-[24px] flex flex-col gap-[12px]">
					<div
						className="flex cursor-pointer items-center gap-[12px]"
						onClick={handleOpenLoginModal}
					>
						{session.isSignedIn ? (
							<Image
								alt="Tick"
								width={32}
								height={32}
								src={'/images/home/rewards/tick-circle.png'}
							/>
						) : (
							<div
								className={classNames(
									'h-[28px] w-[28px] rounded-full bg-brand-50',
									styles.borderOutline
								)}
							></div>
						)}
						<H5 semiBold>{t('gamification.task1')}</H5>
					</div>
					<div
						className="flex cursor-pointer items-center gap-[12px]"
						onClick={handleOpenModal}
					>
						{myQuestions && myQuestions.total > 0 && session.isSignedIn ? (
							<Image
								alt="Tick"
								width={32}
								height={32}
								src={'/images/home/rewards/tick-circle.png'}
							/>
						) : (
							<div
								className={classNames(
									'h-[28px] w-[28px] rounded-full bg-brand-50',
									styles.borderOutline
								)}
							></div>
						)}
						<H5 className="line-clamp-1 text-ellipsis" semiBold>
							{t('gamification.task2')}
						</H5>
					</div>
					<div
						className="flex cursor-pointer items-center gap-[12px]"
						onClick={handleToggleSessionModal}
					>
						<Image
							alt="Gift.png"
							width={32}
							height={32}
							src={'/images/home/rewards/gift.png'}
						/>
						<H5 semiBold>{t('gamification.task3')}</H5>
					</div>
				</div>
			</div>

			<UserInfoModal
				userInfoModal={userInfoModal}
				setUserInfoModal={setUserInfoModal}
				setConfetti={setConfetti}
			/>
			<SessionsModal show={showSessionModal} closeModal={handleToggleSessionModal} />
			{confetti && (
				<ReactConfetti
					width={width}
					// run={confetti}
					height={3000}
					numberOfPieces={300}
					recycle={false}
					drawShape={(ctx) => {
						ctx.beginPath();
						for (let i = 0; i < 22; i++) {
							const angle = 0.35 * i;
							const x = (0.2 + 1.5 * angle) * Math.cos(angle);
							const y = (0.2 + 1.5 * angle) * Math.sin(angle);
							ctx.lineTo(x, y);
						}
						ctx.stroke();
						ctx.closePath();
					}}
					colors={['#FF0000', '#00FF00', '#0000FF', '#FFFF00']}
				/>
			)}
		</div>
	);
};
