import { api } from '@api';
import { Label3, Label4 } from '@pixiepkg/react';
import { SCREEN_SIZES, useWindowSize } from '@shared/hooks/useWindownSize';
import { Schedule } from '@shared/models/Schedule.model';
import { sessionAtom } from '@shared/stores/session.store';
import { useAtom } from 'jotai';
import Image from 'next/image';
import { useEffect, useState } from 'react';

export const DashboardMyReward = () => {
	// const { t } = useTranslation('dashboard');
	const [session] = useAtom(sessionAtom);

	const [mySchedules, setMySchedules] = useState<Schedule[]>();

	const { width } = useWindowSize();

	const Title = width > SCREEN_SIZES.xs ? Label3 : Label4;

	useEffect(() => {
		if (session.isSignedIn) {
			api.getMySchedule({ type: 'p2p' })
				.then((res) => {
					setMySchedules(res.data ?? []);
				})
				.catch(() => setMySchedules([]))
				.finally(() => {});
		}
	}, [session.isSignedIn]);

	return (
		<div className="w-full p-8 2xs:p-2">
			<div className="flex w-[470px] flex-col justify-between gap-[15px] sm:w-full">
				<div className="flex justify-between">
					<div className="flex w-full gap-2">
						<div className="flex w-1/5 flex-col items-center gap-[12px]">
							{mySchedules && mySchedules.length >= 1 ? (
								<Image
									alt="badge"
									src={'/images/home/rewards/Badge.png'}
									width={46}
									height={46}
									className="object-cover"
								/>
							) : (
								<Image
									alt="badge"
									src={'/images/home/rewards/BadgeUncomplete.png'}
									width={46}
									height={46}
									className="object-cover"
								/>
							)}
							<Title className="text-brand-500 2xs:text-sm">1 session</Title>
						</div>
						<div className="flex w-1/5 flex-col items-center gap-[12px]">
							{mySchedules && mySchedules.length >= 2 ? (
								<Image
									alt="badge"
									src={'/images/home/rewards/Badge.png'}
									width={46}
									height={46}
									className="object-cover"
								/>
							) : (
								<Image
									alt="badge"
									src={'/images/home/rewards/BadgeUncomplete.png'}
									width={46}
									height={46}
									className="object-cover"
								/>
							)}
							<Title className="text-brand-500 2xs:text-sm">2 session</Title>
						</div>
						<div className="ml-auto flex w-1/5 flex-col items-center gap-[12px]">
							{mySchedules && mySchedules.length >= 5 ? (
								<Image
									alt="badge"
									src={'/images/home/rewards/Badge.png'}
									width={46}
									height={46}
									className="object-cover"
								/>
							) : (
								<Image
									alt="badge"
									src={'/images/home/rewards/BadgeUncomplete.png'}
									width={46}
									height={46}
									className="object-cover"
								/>
							)}
							<Title className="text-brand-500 2xs:text-sm">5 session</Title>
						</div>
					</div>
				</div>
				<div className="relative h-[13px] w-[446px] rounded-full bg-gray-200 dark:bg-gray-700 sm:w-full">
					<div
						className="h-full rounded-full bg-brand-500"
						style={{
							width:
								mySchedules && mySchedules.length >= 5
									? '100%'
									: mySchedules && mySchedules.length * 20 + '%'
						}}
					></div>
					<div
						style={{
							left:
								mySchedules && mySchedules.length >= 5
									? '100%'
									: mySchedules && mySchedules.length * 20 + '%'
						}}
						className="absolute top-1/2 h-[30px] w-[30px] translate-x-[-50%] translate-y-[-50%] rounded-full border-4 border-brand-50 bg-brand-500"
					></div>
				</div>
			</div>
		</div>
	);
};
