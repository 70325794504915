import { api } from '@api';
import { FormInput } from '@components/FormControl';
import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, KindButton, Label1, Label2 } from '@pixiepkg/react';
import { useSendEvent } from '@shared/hooks/useSendEvent';
import { useTranslation } from 'next-i18next';
import { Fragment, memo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import s from '../styles/UserModal.module.scss';
import { toast } from 'react-toastify';

interface Props {
	userInfoModal: {
		isOpen: boolean;
		from: string;
	};

	setUserInfoModal: (userInfoModal: { isOpen: boolean; from: string }) => void;
	setConfetti: (confetti: boolean) => void;
}

type IForm = {
	name: string;
	phone: string;
	content: string;
};

const UserInfoModal = ({ userInfoModal, setUserInfoModal, setConfetti }: Props) => {
	const { t } = useTranslation('home');
	const { sendEventGAAndBackend } = useSendEvent();

	const schema = yup.object({
		name: yup.string().required(t('errorMessages.name.required')),

		phone: yup
			.string()
			.required(t('errorMessages.phoneNumber.required'))
			.matches(/(84|0[3|5|7|8|9])+([0-9]{8})\b/, t('errorMessages.phoneNumber.number')),

		content: yup.string().required(t('errorMessages.content.required'))
	});
	const { control, handleSubmit, reset } = useForm<IForm>({ resolver: yupResolver(schema) });

	function closeModal() {
		setUserInfoModal({ isOpen: false, from: 'home_page' });
	}

	const handleCreateQuestion = async (data: IForm) => {
		const { name, phone, content } = data;
		setConfetti(false);

		try {
			const res = await api.createQuestion({
				name,
				phone,
				content
			});

			if (res) {
				reset();
				closeModal();
				setConfetti(true);
				toast.success('The answer has been noted. Thank you 😍');
			}

			sendEventGAAndBackend('click_create_question', { from: 'home_page' });
		} catch (error) {
			toast.error('An error occurred. Please try again!');
		}
	};

	const onError = () => {
		console.log('error');
	};

	console.log(t('userInfoModal.surveyPrompt'));

	return (
		<Transition appear show={userInfoModal.isOpen} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-30 mt-8 overflow-y-auto"
				onClose={closeModal}
			>
				<div className="min-h-screen px-4 text-center">
					<Dialog.Overlay
						className="fixed inset-0 bg-black opacity-30"
						onClick={closeModal}
					/>

					<span className="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-50 scale-90"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-300 scale-100"
						leaveTo="opacity-50 scale-90"
					>
						<div className={s.modal}>
							<div className={s.content}>
								<form className={s.form} onSubmit={(e) => e.preventDefault()}>
									<Label1>{t('gamification.userInfoModal.surveyPrompt')}</Label1>
									<div className="flex gap-4 sm:flex-wrap">
										<div className="flex w-1/2 flex-col gap-2 sm:w-full">
											<Label2 className="self-start">
												{t('gamification.userInfoModal.name')}
											</Label2>
											<FormInput
												className="col-span-6"
												control={control}
												name="name"
												rules={{ required: true }}
												showErrorMessage
											/>
										</div>
										<div className="flex w-1/2 flex-col gap-2 sm:w-full">
											<Label2 className="self-start">
												{t('gamification.userInfoModal.phoneNumber')}
											</Label2>
											<FormInput
												showErrorMessage
												type="tel"
												className="col-span-6 xs:col-span-12"
												control={control}
												name="phone"

												// rules={{ required: true, minLength: 8 }}
											/>
										</div>
									</div>
									<div className="flex w-full flex-col gap-2">
										<Label2 className="self-start">
											{t('gamification.userInfoModal.subject')}
										</Label2>
										<FormInput
											className="col-span-6"
											control={control}
											name="content"
											rules={{ required: true }}
											showErrorMessage
										/>
									</div>
								</form>
								<Button
									kind={KindButton.BRAND}
									className="mx-auto mt-[32px] w-[300px] w-full bg-brand-500 md:w-full"
									onClick={() => handleSubmit(handleCreateQuestion, onError)()}
								>
									{t('gamification.userInfoModal.submit')}
								</Button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export const MemoizedUserInfoModal = memo(UserInfoModal);
