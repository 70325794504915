import React, { useEffect, useRef, useState } from 'react';
import { api } from '@api';
import Image from 'next/legacy/image';
import { Webinar, WebinarCollections } from '@shared/models/Webinar.model';
import { CollectionView } from '../CollectionView';
import { H3 } from '@pixiepkg/react';
import { useRouter } from 'next/router';
import { Autoplay, Pagination } from 'swiper';
import { ArrowLeftOutline, ArrowRightOutline } from '@pixiepkg/icon';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import s from './WebinarCollectionsView.module.scss';
import classNames from 'classnames';
import { useSendEvent } from '@shared/hooks/useSendEvent';
import { useImageBlurProps } from '@shared/hooks/useImageBlurProps';
import { useTranslation } from 'next-i18next';

export const WebinarCollectionsView = () => {
	const [collections, setCollections] = useState<WebinarCollections[]>([]);
	const [pWebinars, setPWebinars] = useState<Webinar[]>([]);
	const router = useRouter();
	const swiperRef = useRef<any>();
	const { sendEventGAAndBackend } = useSendEvent();
	const blurProps = useImageBlurProps();
	const { t } = useTranslation('home');

	useEffect(() => {
		try {
			(async () => {
				const data = (await api.getWebinarCollections())?.data;
				if (data && data?.length > 0) {
					setCollections(
						data
							.filter((w) => w.id !== '00000000-0000-0000-0000-000000000000')
							.sort((a, b) => b.startDate - a.endDate)
					);
				}
			})();
			// eslint-disable-next-line no-empty
		} catch (error) {}
	}, []);

	useEffect(() => {
		try {
			(async () => {
				const data = (await api.getIndividualWebinars()).data;
				if (data && data.length > 0) {
					setPWebinars(data.sort((a, b) => b.start - a.start));
				}
			})();
			// eslint-disable-next-line no-empty
		} catch (error) {}
	}, []);

	if ((!collections || !collections.length) && (!pWebinars || !pWebinars.length)) {
		return null;
	}

	const pagination = {
		clickable: true,
		renderBullet: function (index: number, className: string) {
			return '<div class="bottom-0 ' + className + '"></div>';
		}
	};

	return (
		<div className={classNames(s.container, 'py-16')}>
			{pWebinars && !!pWebinars.length && (
				<div>
					<H3 semiBold className={'text-center'}>
						{t('webinars.freeEvents')}
					</H3>
					<div className={classNames('w-full', ' rounded-lg px-4 xs:px-0')}>
						<Swiper
							onBeforeInit={(swiper) => {
								swiperRef.current = swiper;
							}}
							spaceBetween={50}
							slidesPerView={1}
							modules={[Pagination, Autoplay]}
							pagination={pagination}
							scrollbar={{ draggable: true }}
							className="webinar mt-8 !pb-[30px]"
							allowTouchMove
							autoplay={{ delay: 10000 }}
							loop={true}
						>
							<div className="flex flex-col items-center px-4 xs:px-0">
								{pWebinars.map((webinar) => {
									return (
										<SwiperSlide
											key={webinar.id}
											onClick={() => {
												sendEventGAAndBackend('click_webinar_panel', {
													webinar_id: webinar.id,
													collection_id: webinar.collectionID
												});
												router.push(`/webinars/${webinar.id}`);
											}}
										>
											<Image
												key={webinar.id}
												layout="responsive"
												width={1200}
												height={400}
												alt="banner-webinar"
												className="-z- cursor-pointer rounded-lg"
												src={
													webinar.banner ??
													'/images/expert-profile/default-desktop-banner.png'
												}
												objectFit="contain"
												loading="lazy"
												{...blurProps}
											/>
										</SwiperSlide>
									);
								})}
							</div>
						</Swiper>
						<div className={'mt-4 flex justify-center gap-4 2xs:hidden'}>
							<button
								onClick={() => swiperRef.current.slidePrev()}
								className={
									'flex h-[40px] w-[40px] items-center justify-center rounded-lg border border-solid border-gray-100 bg-gray-100 hover:bg-gray-200 active:bg-gray-200'
								}
							>
								<ArrowLeftOutline size={18} color={'#2D3748'} />
							</button>
							<button
								onClick={() => {
									swiperRef.current.slideNext();
								}}
								className={
									'flex h-[40px] w-[40px] items-center justify-center rounded-lg border border-solid border-gray-100 bg-gray-100 hover:bg-gray-200 active:bg-gray-200'
								}
							>
								<ArrowRightOutline size={18} color={'#2D3748'} />
							</button>
						</div>
					</div>
				</div>
			)}

			{collections && !!collections.length && (
				<section className={classNames(' mx-auto mt-16 pb-8 md:m-4', s.container)}>
					<H3 semiBold className="ml-4">
						Tham gia sự kiện cùng Pixie ngay
					</H3>
					{collections.map((collection) => {
						return <CollectionView collection={collection} key={collection.id} />;
					})}
				</section>
			)}
		</div>
	);
};
